// extracted by mini-css-extract-plugin
export var retailerPopupWrapper = "RetailerPopup-module--retailerPopupWrapper--2g6cM";
export var retailerPopup = "RetailerPopup-module--retailerPopup--2RaAD";
export var rowIconText = "RetailerPopup-module--rowIconText--hHefN";
export var closeIcon = "RetailerPopup-module--closeIcon--2NOmr";
export var retailerLogo = "RetailerPopup-module--retailerLogo--3EKaF";
export var orangeItemHeading = "RetailerPopup-module--orangeItemHeading--1nM16";
export var orangeItem = "RetailerPopup-module--orangeItem--Eui1t";
export var planDetails = "RetailerPopup-module--planDetails--3pqGa";
export var planCode = "RetailerPopup-module--planCode--3m-9J";
export var planDetailsText = "RetailerPopup-module--planDetailsText--2eNxm";
export var hr = "RetailerPopup-module--hr--3i6dp";
export var sectionEmail = "RetailerPopup-module--sectionEmail--cJBHA";
export var inputGroup = "RetailerPopup-module--inputGroup--1nmX6";
export var btnSubscribe = "RetailerPopup-module--btnSubscribe--3qrV1";
export var success = "RetailerPopup-module--success--5sCrz";