import React from 'react';
import classNames from 'classnames';

const style = require('./PlanCostSplitTooltip.module.scss');

type PlanCostSplitTooltipProps = {
  className?: string;
  text: string;
  currentTooltipIndex: string;
  visibleTooltipIndex: string | null | undefined;
  [otherProps: string]: any;
};

const PlanCostSplitTooltip = (props: PlanCostSplitTooltipProps) => {
  const { className, text, currentTooltipIndex, visibleTooltipIndex, ...rest } =
    props;

  return (
    <>
      {currentTooltipIndex === visibleTooltipIndex && (
        <div {...rest} className={classNames(style.tooltip, className)}>
          <div>{text}</div>
          <div className={style.arrowDown} />
        </div>
      )}
    </>
  );
};

PlanCostSplitTooltip.defaultProps = {
  className: '',
};

export default PlanCostSplitTooltip;
