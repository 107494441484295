/* eslint camelcase: 0 */
import React, { useEffect, useState } from 'react';
// @ts-ignore
import { Media } from 'gatsby-plugin-fresnel';
import classNames from 'classnames';
import {
  GetRecommendation_resultSack_actualPlan,
  GetRecommendation_resultSack_actualPlan_plan_retailer,
  GetRecommendation_resultSack_recommendationList,
} from '../../../apollo/__generated__/GetRecommendation';
import { PlanCostSplitRow } from '../PlanCostSplitRow';
import { LegendItemType } from '../PlanCostSplitRow/LegendItemType';
import { PlanFeatureFilters } from '../../../utils/enums';
import { FiltersType } from '../../../utils/localStorage/localStorageTypes';
import InfoMessage from '../../ui/InfoMessage';

const style = require('./PlanCostSplit.module.scss');

type PlanCostSplitProps = {
  className?: string;
  actualPlan: GetRecommendation_resultSack_actualPlan | null | undefined;
  recommendedPlan:
    | GetRecommendation_resultSack_recommendationList
    | null
    | undefined;
  // eslint-disable-next-line react/boolean-prop-naming
  activeResultFilters?: FiltersType | null;
  currentRetailer?: GetRecommendation_resultSack_actualPlan_plan_retailer | null;
  isActualPlanUserSelected: boolean | null;
  [otherProps: string]: any;
};

const PlanCostSplit = (props: PlanCostSplitProps) => {
  const {
    className,
    actualPlan,
    recommendedPlan,
    activeResultFilters,
    currentRetailer,
    isActualPlanUserSelected,
    ...rest
  } = props;

  const greenPowerRequired = activeResultFilters?.planFeature?.includes(
    PlanFeatureFilters.greenPowerRequired
  );

  const allDiscountsRequired = activeResultFilters?.planFeature?.includes(
    PlanFeatureFilters.discountRequired
  );

  const guaranteedDiscountsRequired =
    activeResultFilters?.planFeature?.includes(
      PlanFeatureFilters.guaranteedDiscountRequired
    );

  const [legendItemsNew, setLegendItemsNew] = useState<LegendItemType[]>([
    {
      title: 'Usage cost',
      colorClassName: 'statBgColorBlue',
      actualValue: 0,
      recommendedValue: 0,
    },
  ]);

  const [showGreenPowerWarning, setShowGreenPowerWarning] = useState(false);

  useEffect(() => {
    const legendItemsArray: LegendItemType[] = [
      {
        title: 'Usage cost',
        colorClassName: 'statBgColorBlue',
        actualValue: parseFloat(actualPlan?.annualUsageCost) ?? 0,
        // @ts-ignore
        recommendedValue: parseFloat(recommendedPlan?.annualUsageCost) * 1.1 ?? 0,
      },
      {
        title: 'Daily supply charge',
        colorClassName: 'statBgColorOrange',
        actualValue: parseFloat(actualPlan?.annualDspCost) ?? 0,
        // @ts-ignore
        recommendedValue: parseFloat(recommendedPlan?.annualDspCost) * 1.1 ?? 0,
      },
      {
        title: 'Controlled load',
        colorClassName: 'statBgColorRed',
        actualValue: parseFloat(actualPlan?.annualControlledLoad) ?? 0,
        // @ts-ignore
        recommendedValue: parseFloat(recommendedPlan?.annualControlledLoad * 1.1) ?? 0,
      },
      {
        title: 'Membership',
        colorClassName: 'statBgColorGrey',
        actualValue: parseFloat(actualPlan?.annualFeeTotal) ?? 0,
        recommendedValue: parseFloat(recommendedPlan?.annualFeeTotal) ?? 0,
      },
      {
        title: 'Solar export',
        colorClassName: 'statBgColorYellow',
        positive: true,
        actualValue: parseFloat(actualPlan?.annualSolarExport) ?? 0,
        recommendedValue: parseFloat(recommendedPlan?.annualSolarExport) ?? 0,
      },
    ];
    if (guaranteedDiscountsRequired) {
      legendItemsArray.push({
        title: 'Discount',
        colorClassName: 'statBgColorBlueDark',
        positive: true,
        actualValue: parseFloat(actualPlan?.annualValueGuaranteedDiscount) ?? 0,
        recommendedValue:
          // @ts-ignore
          parseFloat(recommendedPlan?.annualValueGuaranteedDiscount) ?? 0,
      });
    } else if (allDiscountsRequired) {
      legendItemsArray.push({
        title: 'Discount',
        colorClassName: 'statBgColorBlueDark',
        positive: true,
        actualValue: parseFloat(actualPlan?.annualValueDiscount) ?? 0,
        // @ts-ignore
        recommendedValue: parseFloat(recommendedPlan?.annualValueDiscount) ?? 0,
      });
    }

    if (greenPowerRequired) {
      legendItemsArray.push({
        title: 'Green power',
        colorClassName: 'statBgColorGreen',
        actualValue: parseFloat(actualPlan?.annualGreenPowerChargesPct100) ?? 0,
        recommendedValue:
          // @ts-ignore
          parseFloat(recommendedPlan?.annualGreenPowerChargesPct100) * 1.1 ?? 0,
      });

      if (!actualPlan?.annualGreenPowerChargesPct100) {
        setShowGreenPowerWarning(true);
      }
    }

    setLegendItemsNew(legendItemsArray);
  }, [actualPlan, recommendedPlan]);

  const legendItemsReversed = legendItemsNew.slice().reverse();

  const [subheadingText, setSubheadingText] = useState('');

  useEffect(() => {
    if (isActualPlanUserSelected) {
      setSubheadingText(`Your plan with ${currentRetailer?.orgName}`);
      return;
    }

    setSubheadingText(`The median plan offer`);
  }, [currentRetailer, isActualPlanUserSelected]);

  return (
    <div {...rest} className={classNames(style.planCostSplit, className)}>
      <div className={style.legend}>
        {legendItemsNew.map(
          (legendItem) =>
            (!!legendItem.actualValue || !!legendItem.recommendedValue) && (
              <div className={style.legendItem} key={legendItem.title}>
                <div>{legendItem.title}</div>
                <div
                  className={`${style.legendItemColor} ${legendItem.colorClassName}`}
                  title={` This plan: $${Math.round(
                    legendItem?.recommendedValue
                  )}\nYour plan: $${Math.round(legendItem?.actualValue)}`}
                />
              </div>
            )
        )}
      </div>
      <div className={style.plot}>
        <Media lessThan="tablet">
          <h5 className="mt-3">
            This plan with {recommendedPlan?.plan?.retailer?.orgName}
          </h5>
        </Media>
        <Media greaterThanOrEqual="tablet">
          <h4 className="mb-3">
            This plan with {recommendedPlan?.plan?.retailer?.orgName}
          </h4>
        </Media>
        <PlanCostSplitRow
          legendItemsReversed={legendItemsReversed}
          dataSource="recommended"
        />
        <Media lessThan="tablet">
          <h5 className="mt-3">{subheadingText}</h5>
        </Media>
        <Media greaterThanOrEqual="tablet">
          <h4 className="mt-4 mb-3">{subheadingText}</h4>
        </Media>
        <PlanCostSplitRow
          legendItemsReversed={legendItemsReversed}
          dataSource="actual"
        />
      </div>
      {showGreenPowerWarning && (
        <>
          <div />
          <div className={style.greenPowerWarningContainer}>
            <InfoMessage variant="warning">
              Your current plan does not provide a green power option.
            </InfoMessage>
          </div>
        </>
      )}
    </div>
  );
};

PlanCostSplit.defaultProps = {
  activeResultFilters: null,
  className: '',
  currentRetailer: '',
};

export default PlanCostSplit;
