import React from 'react';

const style = require('./PlanPriceBarplot.module.scss');

type PlanPriceBarplotProps = {
  className?: string;
  yourPlanCost: number;
  thisPlanCost: number;
  defaultBarHeight?: number;
  isActualPlanUserSelected: boolean | null;
  [otherProps: string]: any;
};

const PlanPriceBarplot = (props: PlanPriceBarplotProps) => {
  const {
    className,
    bars,
    yourPlanCost,
    thisPlanCost,
    defaultBarHeight = 150,
    isActualPlanUserSelected,
    ...rest
  } = props;

  let thisPlanBarHeight = 10;

  if (
    (thisPlanCost > 0 && yourPlanCost > 0) ||
    (thisPlanCost < 0 && yourPlanCost < 0)
  ) {
    thisPlanBarHeight = Math.floor(
      (Math.abs(thisPlanCost) / Math.abs(yourPlanCost)) * defaultBarHeight
    );
  }

  const percentageDifference = Math.ceil(
    ((yourPlanCost - thisPlanCost) /
      ((Math.abs(thisPlanCost) + Math.abs(yourPlanCost)) / 2)) *
      100
  );

  const yourPlanCostDisplay = `$${
    yourPlanCost > 0 ? yourPlanCost : `${Math.abs(yourPlanCost)}Cr`
  }`;
  const thisPlanCostDisplay = `$${
    thisPlanCost > 0 ? thisPlanCost : `${Math.abs(thisPlanCost)}Cr`
  }`;

  return (
    <div {...rest} className={`${style.planPriceBarplot} ${className}`}>
      <div className={style.barColumn}>
        <div
          className={`${style.bar} ${style.barGreen}`}
          style={{ height: `${defaultBarHeight}px` }}
          title={`Your plan: +${percentageDifference}%`}
        />
        <div className={style.caption}>
          <div className={style.captionTitle}>
            {isActualPlanUserSelected ? 'Your plan' : 'Median plan'}
          </div>
          <div className={style.captionPrice}>{yourPlanCostDisplay} Month</div>
        </div>
      </div>
      <div className={style.barColumn}>
        <div
          className={`${style.bar} ${style.barOrange}`}
          style={{ height: `${thisPlanBarHeight}px` }}
          title={`This plan: ${-percentageDifference}%`}
        />
        <div className={style.caption}>
          <div className={style.captionTitle}>This plan</div>
          <div className={style.captionPrice}>{thisPlanCostDisplay} Month</div>
        </div>
      </div>
    </div>
  );
};

PlanPriceBarplot.defaultProps = {
  className: '',
  defaultBarHeight: 150,
};

export default PlanPriceBarplot;
