import { useEffect } from 'react';

/**
 * Trigger the handler when clicked on an element, that (or whose child elements) don't have a specific class assigned.
 * @param {string} classNameToIgnore - a class name to ignore for click event
 * @param {function} handler - a function to run on click event
 */
const useOnClickOutsideExceptClasses = (classNameToIgnore, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements

        if (
          event.target.classList.contains(classNameToIgnore) ||
          event.target.closest(`.${classNameToIgnore}`)
        ) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },

    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.

    [classNameToIgnore, handler]
  );
};

export default useOnClickOutsideExceptClasses;
