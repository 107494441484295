/* eslint camelcase: 0 */
import { navigate } from 'gatsby';
import { nanoid } from 'nanoid';
import { GetLocalities2_getLocalities } from '../apollo/__generated__/GetLocalities2';
import { GetLocality_getLocality } from '../apollo/__generated__/GetLocality';
import { GetRecommendation_resultSack_recommendationList_plan } from '../apollo/__generated__/GetRecommendation';
import { StepUrl } from './enums';
import { setActiveStepUrlLS } from './localStorage/localStorageFunctions';

/**
 * Returns the median of an array
 */
export const getArrayMedian = (arr: any[] | null | undefined): any | null => {
  if (!arr || !arr.length) return null;
  if (arr.length === 1) return arr[0];

  const mid = Math.floor(arr.length / 2);

  return arr.length % 2 !== 0 ? arr[mid] : arr[mid + 1];
};

export const getTariffTypeFilter = (
  plan: GetRecommendation_resultSack_recommendationList_plan | null | undefined
) => {
  if (plan?.srplan) return 'Single rate';
  if (plan?.touplan) return 'Time of use';
  if (plan?.blockplan) return 'Block rates';

  return 'Other';
};

/**
 * Get ordinal suffix of a number (1st, 2nd, 3rd, 10th)
 * @param n
 */
export const nth = (n: number) => {
  return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
};

export const navigateAndSetActiveStep = (
  stepUrl: StepUrl,
  options?: object,
  shouldReplace: boolean = false
) => {
  setActiveStepUrlLS(stepUrl);
  navigate(stepUrl, { ...options, replace: shouldReplace });
};

export const roundToDecimalPlaces = (num: number, places: number) => {
  const epsilonString = (num + Number.EPSILON).toLocaleString(undefined, {
    minimumFractionDigits: 20,
  });
  return Math.round(parseFloat(epsilonString) * 10 ** places) / 10 ** places;
};

export const roundToDecimalPlacesAndPad = (num: number, places: number) => {
  return roundToDecimalPlaces(num, places).toFixed(places);
};

export const generateRandomIds = (length: number) => {
  return Array.from({ length }, () => nanoid());
}

export const getLocalityFromDetail = (detail: GetLocality_getLocality): GetLocalities2_getLocalities => {
  return {
    __typename: "LocalityType",
    id: detail.id,
    name: detail.name,
    postcode: detail.postcode,
    state: detail.state
  }
}