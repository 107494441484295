import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { LegendItemType } from './LegendItemType';
import PlanCostSplitTooltip from '../PlanCostSplitTooltip';
import useOnClickOutsideExceptClasses from '../../../hooks/useOnClickOutsideExceptClasses';

const style = require('./PlanCostSplitRow.module.scss');

type PlanCostSplitBrickProps = {
  legendItem: LegendItemType;
  percentSource: 'actualPercent' | 'recommendedPercent';
  valueSource: 'actualValue' | 'recommendedValue';
  visibleToolTip: string | null | undefined;
  setVisibleTooltip: Function;
};

const PlanCostSplitBrick = (props: PlanCostSplitBrickProps) => {
  const {
    legendItem,
    percentSource,
    visibleToolTip,
    valueSource,
    setVisibleTooltip,
  } = props;

  return (
    <div
      key={legendItem?.title}
      className={style.plotBrickWrapper}
      style={{
        width: `${legendItem[percentSource]}%`,
      }}
    >
      <PlanCostSplitTooltip
        className={classNames(
          style.plotBrickTooltip,
          style.ignoreOutsideClickPlotBrick
        )}
        text={`${legendItem.title}: $${Math.round(
          legendItem?.[valueSource] ?? 0
        )}`}
        visibleTooltipIndex={visibleToolTip}
        currentTooltipIndex={legendItem?.title}
      />
      <div
        onClick={() => {
          setVisibleTooltip(legendItem?.title);
        }}
        onMouseEnter={() => {
          setVisibleTooltip(legendItem?.title);
        }}
        onMouseLeave={() => {
          setVisibleTooltip(null);
        }}
        className={classNames(
          style.plotBrick,
          legendItem?.colorClassName,
          'ignoreOutsideClickPlotBrick'
        )}
      />
    </div>
  );
};

export const PlanCostSplitRow = ({
  legendItemsReversed,
  dataSource,
}: {
  legendItemsReversed: LegendItemType[];
  dataSource: 'actual' | 'recommended';
}) => {
  const percentSource = `${dataSource}Percent` as
    | 'actualPercent'
    | 'recommendedPercent';

  const valueSource = `${dataSource}Value` as
    | 'actualValue'
    | 'recommendedValue';

  const get100PercentBase = useCallback((allLegendItems: LegendItemType[]) => {
    const actualValuesTotal = allLegendItems.reduce(
      (acc, legendItem) => acc + (legendItem.actualValue ?? 0),
      0
    );

    const recommendedValuesTotal = allLegendItems.reduce(
      (acc, legendItem) => acc + (legendItem.recommendedValue ?? 0),
      0
    );

    return Math.max(actualValuesTotal, recommendedValuesTotal);
  }, []);

  const calculatePercentages = useCallback(
    (allLegendItems: LegendItemType[]) => {
      const total100PercentBase = get100PercentBase(allLegendItems);

      return allLegendItems.map((legendItem) => {
        legendItem.actualPercent =
          (legendItem.actualValue / total100PercentBase) * 100;
        legendItem.recommendedPercent =
          (legendItem.recommendedValue / total100PercentBase) * 100;
        return legendItem;
      });
    },
    []
  );

  const calculateLeftRightRatio = useCallback(
    (
      leftSideLegendItems: LegendItemType[],
      rightSideLegendItems: LegendItemType[]
    ) => {
      const leftSideActual = leftSideLegendItems.reduce(
        (acc, legendItem) => acc + (legendItem.actualValue ?? 0),
        0
      );
      const leftSideRecommended = leftSideLegendItems.reduce(
        (acc, legendItem) => acc + (legendItem.recommendedValue ?? 0),
        0
      );
      const rightSideActual = rightSideLegendItems.reduce(
        (acc, legendItem) => acc + (legendItem.actualValue ?? 0),
        0
      );
      const rightSideRecommended = rightSideLegendItems.reduce(
        (acc, legendItem) => acc + (legendItem.recommendedValue ?? 0),
        0
      );

      const maxLeft = Math.max(leftSideActual, leftSideRecommended);
      const maxRight = Math.max(rightSideActual, rightSideRecommended);

      return (maxLeft / (maxLeft + maxRight)) * 100;
    },
    []
  );

  const leftSideLegendItems = useMemo(
    () => legendItemsReversed.filter((legendItem: any) => legendItem.positive),
    [legendItemsReversed]
  );

  const rightSideLegendItems = useMemo(
    () => legendItemsReversed.filter((legendItem: any) => !legendItem.positive),
    [legendItemsReversed]
  );

  const leftSideLegendItemsWPercent = useMemo(
    () => calculatePercentages(leftSideLegendItems),
    [leftSideLegendItems]
  );

  const rightSideLegendItemsWPercent = useMemo(
    () => calculatePercentages(rightSideLegendItems),
    [rightSideLegendItems]
  );

  const leftSidePercent = useMemo(
    () => calculateLeftRightRatio(leftSideLegendItems, rightSideLegendItems),
    [leftSideLegendItems, rightSideLegendItems]
  );
  const rightSidePercent = 100 - leftSidePercent;

  const [visibleToolTip, setVisibleTooltip] = useState<string | null>();

  useOnClickOutsideExceptClasses(style.ignoreOutsideClickPlotBrick, () => {
    setVisibleTooltip(null);
  });

  return (
    <div className={style.plotRow}>
      <div
        className={style.plotLeftSide}
        style={{ width: `${leftSidePercent}%` }}
      >
        {leftSideLegendItemsWPercent.map((legendItem) => {
          if (legendItem?.[percentSource] === 0) return;
          return (
            <PlanCostSplitBrick
              key={legendItem?.title}
              legendItem={legendItem}
              percentSource={percentSource}
              valueSource={valueSource}
              setVisibleTooltip={setVisibleTooltip}
              visibleToolTip={visibleToolTip}
            />
          );
        })}
      </div>
      <div className={style.barSeparator} />
      <div
        className={style.plotRightSide}
        style={{ width: `${rightSidePercent}%` }}
      >
        {rightSideLegendItemsWPercent.map((legendItem) => {
          if (legendItem?.[percentSource] === 0) return;
          return (
            <PlanCostSplitBrick
              key={legendItem?.title}
              legendItem={legendItem}
              percentSource={percentSource}
              valueSource={valueSource}
              setVisibleTooltip={setVisibleTooltip}
              visibleToolTip={visibleToolTip}
            />
          );
        })}
      </div>
    </div>
  );
};
